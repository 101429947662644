import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useAureliaI18n } from '../../../react-hooks/useAureliaI18n';
import NavButton from './NavButton';

const ToDoMenuPopover = ({ client }) => {
  const [menuData, setMenuData] = useState([]);
  const { t } = useAureliaI18n();
  const [menuPosition, setMenuPosition] = useState({});
  const [duePoints, setDuePoints] = useState(0);
  const buttonRef = useRef(null);

  useEffect(() => {
    if (buttonRef.current) {
      const position = buttonRef.current.getBoundingClientRect();
      setMenuPosition(position);
    }
  }, [buttonRef.current]);

  const groupBy = (xs, key) => {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  const showTodoItems = async () => {
    const params = new URLSearchParams({
      offset: '0',
      'sort%5B0%5D%5B%5D': ['DESC'],
      conditions: JSON.stringify({
        $and: [
          { done: { $ne: true } },
          { assigned: '@user' },
          [],
          {
            deadline: {
              $lte: moment().format('YYYY-MM-DDTHH:mmZ'),
            },
          },
        ],
      }),
    });

    const data = await client.get('todo/todo-entry?' + params.toString());
    const remainingTodo = await client.get('todo/todo-entry/dues');
    const items = data.items || [];
    setMenuData(
      [...items].map((item) => {
        return {
          name: item.name,
          deadline: item.deadline,
          id: item.id,
        };
      })
    );
    setDuePoints(remainingTodo)
  };
  useEffect(() => {
    showTodoItems();
  }, []);
  const groupedData = groupBy(menuData, 'deadline') || [];
  return (
    <NavButton
      className="hover-button"
      ref={buttonRef}
      title={t('todo.go-to-todo')}
      onClick={() => {
        window.location.href = '/view/todo/list';
      }}
    >
      <a href="/view/todo/list" className="sio-badge-link">
        <i className="fa fa-list-check fa-sm" />
        {duePoints > 0 && (
          <span className="badge">
            {duePoints}
          </span>
        )}
      </a>
      <div id="menuPopover">
        <div
          className="popover-menu-open"
          style={{
            top: `${menuPosition?.bottom}px`,
            left: `${menuPosition?.left}px`,
          }}
        >
          <p style={{ fontWeight: 'bold' }}>Fällige Aufgaben:</p>
          {Object.keys(groupedData).length == 0
            ? 'Keine demnächst fälligen Aufgaben'
            : Object.keys(groupedData).map((item) => {
                return (
                  <div
                    key={item}
                    style={{
                      paddingBottom: '2px',
                      textAlign: 'left',
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: '#f3f3f3',
                        width: '100%',
                        display: 'inline-block',
                        padding: '2px 3px',
                      }}
                    >
                      {moment(item).format('DD.MM.YYYY')}
                    </span>
                    {groupedData[item].map((item) => {
                      return (
                        <a
                          style={{
                            fontSize: '14px',
                            display: 'block',
                            cursor: 'pointer',
                          }}
                          href={'/view/todo/todo-entry-view?id=' + item.id}
                          key={item.id}
                        >
                          {item.name}
                        </a>
                      );
                    })}
                  </div>
                );
              })}
        </div>
      </div>
    </NavButton>
  );
};

export default ToDoMenuPopover;
